// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-support-index-js": () => import("./../src/pages/support/index.js" /* webpackChunkName: "component---src-pages-support-index-js" */),
  "component---src-pages-support-privacy-policy-js": () => import("./../src/pages/support/privacy-policy.js" /* webpackChunkName: "component---src-pages-support-privacy-policy-js" */),
  "component---src-pages-support-terms-of-service-js": () => import("./../src/pages/support/terms-of-service.js" /* webpackChunkName: "component---src-pages-support-terms-of-service-js" */),
  "component---src-templates-game-item-jsx": () => import("./../src/templates/game-item.jsx" /* webpackChunkName: "component---src-templates-game-item-jsx" */)
}

